/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import {
    object, string, func, shape, bool,
} from 'prop-types';
import mbpLogger from 'mbp-logger';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { connect } from 'react-redux';
import {  getFeatureFlags } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getEnterpriseId } from '../../../../../state/ducks/Member/ducks/EnterpriseId/EnterpriseId-Selectors';
import { getIsBot, getUserSubmittedProductFilterZipcode } from '../../../../../state/ducks/App/App-Selectors';
import { withAuth } from '../../../../../state/ducks/Member/ducks/Auth/Plugin/auth';
import getPriceFromEngine from '../../../../../apis/product-apis/priceEngine';
import SkeletonStyles from '../../GraphqlSkeletonComponents/SkeletonStyles';
import { getBaseCode } from '../../../../helpers/tracking/product/dataLayerProductHelpers';

const styles = (theme) => ({
    ...SkeletonStyles,
    thumbName: {
        padding: '5px 0',
        [theme.breakpoints.down(700)]: {
            padding: '1px 10% 5px 0',
        },
    },
    thumbPrice: {
        padding: '5px 0 10px',
        fontWeight: 600,
    },
});

const Product = ({
    data, phrase, track, concatSearchTerm,
    clearSearch, classes, getPrice, featureFlags,
    enterpriseId, userSubmittedZip,
}) => {
    const partNumber = data?.productSkus?.[0]?.id ||  data?.partNumber;
    const [price, setPrice] = useState(data?.prices || []);
    // const [dynamicPriceData, setDynamicPriceData] = useState(null);
    const [dynamicPriceLoading, setDynamicPriceLoading] = useState(false);
    useEffect(() => {
        async function fetchPriceFromEngine() {
            if (!featureFlags['is-dynamic-pricing-enabled']) {
                setPrice(data?.prices);
                return;
            }

            if (!enterpriseId || !partNumber) {
                setPrice(data?.prices);
                return;
            }

            setDynamicPriceLoading(true);
            const payload = {
                enterpriseId,
                products: [
                    {
                        partNumber,
                    },
                ],
                variants: [],
            };

            if (featureFlags['is-zip-dynamic-pricing-enabled'] &&  userSubmittedZip) {
                payload.variants.push({
                    name: 'ZIP_CODE',
                    value: userSubmittedZip,
                });
            }

            getPriceFromEngine({}, null, payload, featureFlags['is-price-engine-batching-enabled'])
                .then((res) => {
                    setPrice(res?.data?.products?.[0]?.prices || data?.prices);
                    // setDynamicPriceData(res?.data?.products?.[0]);
                    setDynamicPriceLoading(false);
                })
                .catch((ex) => {
                    mbpLogger.logError({
                        appName: process.env.npm_package_name,
                        jsError: ex,
                        message: 'AutoSuggest:: fetch prices failed"',
                        enterpriseId,
                        partNumber,
                    });
                    setDynamicPriceLoading(false);
                });
        }

        fetchPriceFromEngine();
    }, [
        featureFlags,
        enterpriseId,
        partNumber,
    ]);
    return (
        <>
            <Link
                to={concatSearchTerm(data?.seo?.url, phrase)}
                onClick={() => {
                    track({
                        eventCategory: 'Site Search',
                        eventAction: 'auto suggest product click',
                        eventLabel: `${partNumber} | ${ReactHtmlParser(data.name)}`,
                        searchVariety: 'Suggested',
                        eventName: 'search',
                        searchPhrase: phrase,
                        searchProductId: getBaseCode(partNumber),
                    });
                    clearSearch();
                }}
                tabIndex="-1"
                title={ReactHtmlParser(data.name)}
            >
                <img alt={ReactHtmlParser(data.name)} className="thumb-image" src={`${data.image?.path}${data.image?.name}t.jpg`} />
                <span className={classes.thumbName}>{ReactHtmlParser(data.name)}</span>
                {
                    !featureFlags['is-autosuggest-hide-pricing-enabled']
                    && (!dynamicPriceLoading ? (
                        <span className={classes.thumbPrice}>
                            {`${'$'}${getPrice(price)}`}
                        </span>
                    ) : (
                        <>
                            <span className={classes.thumbPrice}>
                                <span className={`${classes.textLG} ${classes.shimmer}`}>
                                    {' '}
                                </span>
                            </span>
                        </>
                    ))
                }
            </Link>
            {/* { !featureFlags['is-autosuggest-hide-pricing-enabled']
            && (!dynamicPriceLoading ? (
                <div>
                    <PriceBreakDownUi
                        dynamicPriceData={dynamicPriceData}
                    />
                </div>
            ) : <></>)} */}
        </>
    );
};

Product.propTypes = {
    classes: object.isRequired,
    data: shape({
        seo: shape({
            url: string,
        }),
    }).isRequired,
    phrase: string.isRequired,
    clearSearch: func.isRequired,
    track: func.isRequired,
    concatSearchTerm: func.isRequired,
    getPrice: func.isRequired,
    featureFlags: shape({
        'is-dynamic-pricing-enabled': bool,
    }),
    enterpriseId: string,
    userSubmittedZip: string,
};

Product.defaultProps = {
    enterpriseId: '',
    featureFlags: {},
    userSubmittedZip: '',
};

const mapStateToProps = (state) => ({
    featureFlags: getFeatureFlags(state),
    enterpriseId: getEnterpriseId(state),
    isBot: getIsBot(state),
    userSubmittedZip: getUserSubmittedProductFilterZipcode(state),
});

const enhance = compose(
    withStyles(styles),
    withAuth,
    connect(mapStateToProps, null),
);

export default enhance(Product);
